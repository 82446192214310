import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

import i18n from '@/libs/i18n'

// some plugins
import VueSweetalert2 from 'vue-sweetalert2'
Vue.use(VueSweetalert2)

// Composition API
Vue.use(VueCompositionAPI)

// Icons
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
Vue.component(FeatherIcon.name, FeatherIcon)

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('font-awesome-icon', FontAwesomeIcon)

//Vuesax Component Framework
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css';
import 'material-icons/iconfont/material-icons.css';
Vue.use(Vuesax)

Vue.use(require('vue-cookies'))
Vue.prototype.$logout = function () {
  fetch('https://api.njm.solutions/logout', 
  {
    credentials: 'include',
    method: 'POST',
  }).then(res=>res.json()).then(res=> {
    store.state.userData = {};
    this.$router.push({ name: 'home' })
  })
  // this.$router.go(0)
}

// import core styles
require('@core/scss/core.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
