export default {
  namespaced: true,
  state: {
    national_id: null,
    name: null,
    phone: null
  },
  getters: {},
  mutations: {},
  actions: {},
}
