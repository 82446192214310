<template>
  <div
    id="app"
  >
    <layout>
      <router-view />
    </layout>

  </div>
</template>

<script>
import { useCssVar } from '@vueuse/core'

import Layout from '@core/layouts/layout.vue'
import i18n from '@/libs/i18n'
export default {
  components: {
    Layout,
  },
  beforeCreate() {
    const colors = {
      'primary' : '', 'secondary' : '', 'success': '', 'info': '', 'warning': '', 'danger': '', 'light': '', 'dark' :''
    };
    for (let i = 0, len = Object.keys(colors).length; i < len; i++) {
      const colorName =  Object.keys(colors)[i];
      colors[colorName] = useCssVar(`--${colorName}`, document.documentElement).value.trim()
    }
    this.$vs.theme(colors)
    const isRTL = i18n.locale == 'ar'
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  }
}
</script>
