<template>
  <ul id="main-menu-navigation" class="nav navbar-nav">
    <b-link to="/" class="mr-4 align-self-center">
      <span class="njm-logo">
        <b-img :src="NjmLogo" alt="مساعد نجم" />
      </span>
    </b-link>
    <li class="mx-25" v-for="item in items" :key="item.header || item.title" :class="{
      'active': ActiveRoute == item.route,
      'disabled': item.disabled
    }">
      <b-link class="dropdown-item m-auto" :to="{ name: item.route }">
        <span class="menu-title">{{ t(item.route) }}</span>
      </b-link>
    </li>
  </ul>
</template>
<script>


import { BLink, BImg } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  components: {
    BLink, BImg
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ActiveRoute() {
      return this.$route.name;
    },
    NjmLogo() {
      return require("@/assets/images/logo/logo.png")
    }
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      // i18n
      t,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
<style scoped>
.njm-logo img {
  max-height: 25px !important;
}
</style>
