export default [
  {
    title: 'home',
    route: 'home',
  },
  {
    title: 'about',
    route: 'about',
  },
  {
    title: 'history',
    route: 'history',
  }
]
