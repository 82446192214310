<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
    <div class="navbar-horizontal d-none d-xl-block">
      <menu-items :items="navMenuItems" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-button variant="flat-primary" :to="{ name: 'new-report' }" class="mr-50" pill>
      <feather-icon icon="PlusIcon" class="mr-50"/>
      قدم بلاغك
      </b-button>
    <b-button variant="outline-primary" class="mr-50" pill v-if="$store.state.userData && $store.state.userData.national_id !== null && $store.state.userData.national_id !== undefined" @click="$logout()">
    تسجيل الخروج
    </b-button>
      <b-button v-if="!$store.state.userData || $store.state.userData.national_id == null" :to="{name:'login'}" variant="outline-primary" pill>تسجيل الدخول</b-button>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BImg, BButton, BOverlay
} from 'bootstrap-vue'

import store from '@/store'
import navMenuItems from '@/navigation'
import menuItems from './menuItems.vue'

export default {
  components: {
    BLink,
    BImg,
    BButton,
    BOverlay,
    menuItems,
    BNavbarNav,
  },
  computed: {
    isLoggedIn() {
      return store.state.userData && store.state.userData.national_id !== null
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      navMenuItems
    }
  }
}
</script>