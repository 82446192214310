<template>
  <div :class="[...classes]" data-col="1-column">
    <!-- Vertical Nav Menu -->
    <vertical-nav-menu :is-vertical-menu-active="isVerticalMenuActive"
      :close="toggleVerticalMenuActive" class="d-block d-xl-none">
      <template #header="slotProps">
        <slot name="vertical-menu-header" v-bind="slotProps" />
      </template>
    </vertical-nav-menu>
    <!-- /Vertical Nav Menu -->
    <!-- Navbar -->
    <div class="header-navbar navbar-fixed">
      <top-section />
      <b-navbar :toggleable="false">
        <slot name="navbar">
          <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
        </slot>
      </b-navbar>
    </div>
    <!--/ Navbar -->

    <!-- Vertical Nav Menu Overlay -->
    <div class="njm-body-overlay" :class="{ 'show': isVerticalMenuActive && CBreakpoint !== 'xl' }"
      @click="isVerticalMenuActive = false" />
    <!-- /Vertical Nav Menu Overlay -->

    <div class="app-content" :class="['content', $route.meta.contentClass]">
      <div class="content-wrapper">
        <div class="content-body">
          <transition name="zoom-fade" mode="out-in">
            <router-view />
          </transition>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <njm-footer />
    <!-- /Footer -->

  </div>
</template>

<script>
import navbar from '@core/layouts/components/navbar'
import NjmFooter from '@core/layouts/components/Footer.vue'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import { ref } from '@vue/composition-api'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
library.add(faTwitter, faLinkedinIn);
import { BImg, BLink, BNavbar, BRow, BCol } from 'bootstrap-vue'
import topSection from './components/top-section'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BNavbar,
    BLink,
    topSection,
    navbar,
    VerticalNavMenu,
    NjmFooter
  },
  created() {
    this.updateClasses();
    this.updateBreakpoint()
    window.addEventListener("resize", this.updateBreakpoint);
  },
  destroyed() {
    window.removeEventListener("resize", this.updateBreakpoint);
  },
  watch: {
    CBreakpoint() {
      this.updateClasses()
    },
    isVerticalMenuActive() {
      this.updateClasses()
    },
  },
  methods: {
    updateBreakpoint() {
      if (window.innerWidth >= 1200) this.CBreakpoint = 'xl'
      else if (window.innerWidth >= 992) this.CBreakpoint = 'lg'
      else if (window.innerWidth >= 768) this.CBreakpoint = 'md'
      else if (window.innerWidth >= 576) this.CBreakpoint = 'sm'
      else this.CBreakpoint = 'xs'
      if (this.CBreakpoint == 'xl') this.isVerticalMenuActive = false;
    },
    updateClasses() {
      const newClasses = [];
      if (this.CBreakpoint == 'xl') {
        newClasses.push('horizontal-menu')
      } else {
        newClasses.push('vertical-overlay-menu')
        newClasses.push(this.isVerticalMenuActive ? 'menu-open' : 'menu-hide')
      }
      this.classes = newClasses;
    }
  },
  setup() {

    const CBreakpoint = ref('xl')
    const isVerticalMenuActive = ref(false)
    const toggleVerticalMenuActive = () => {
      isVerticalMenuActive.value = !isVerticalMenuActive.value
    }

    const classes = ref([]);

    return {
      isVerticalMenuActive, toggleVerticalMenuActive,
      classes,
      CBreakpoint
    }
  },
}
</script>

<style lang="scss" scoped>
.njm-body-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 120vh;
  background-color: rgba(#000000, 0.5);
  z-index: 997;
  display: block;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;

  &.show {
    opacity: 1;
    visibility: visible;
    transition: all 0.5s ease;
  }
}
</style>