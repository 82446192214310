<template>
  <div
    class="main-menu"
    :class="[
      'menu-light'
    ]"
  >
    <!-- main menu header-->
    <div>
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
      >
        <ul class="text-center mt-2 mb-1">
          <li>
            <b-link
              class="navbar-njm"
              :to="{name:'home'}"
            >
                <b-img
                  :src="NjmLogo"
                  alt="مساعد نجم"
                />
            </b-link>
          </li>

        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation"
        @close="close"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from '@/navigation'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import VerticalNavMenuItems from './components/VerticalNavMenuItems.vue'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  computed: {
    NjmLogo() {
      return require("@/assets/images/logo/logo.png")
    }
  },
  props: {
    close: {
      
    },
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }
    return {
      navMenuItems,
      perfectScrollbarSettings,
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar-njm {
  img {
    max-width: 150px;
  }
}
</style>
