<template>
      <footer class="footer">
      <div class="f-long">
      <b-row>
        <b-col>
          <h3><feather-icon icon="PhoneIcon" size="19" class="mr-50"/>اتصل بنا: <b-link href="tel:199033" class="call d-inline">199033</b-link></h3>

          <h4 class="mb-50">تطبيق مساعد نجم</h4>
          <b-img class="d-block mb-50 cursor-pointer" width="150"
            :src="require('@/assets/images/other/app_store_badge.png')" @click="appButtonClicked" />
          <b-img width="150" class="cursor-pointer" :src="require('@/assets/images/other/google_play_badge.png')"
            @click="appButtonClicked" />
        </b-col>
        <b-col>
          <h3>مساعد نجم</h3>
          <b-link :to="{ name: 'about' }">من نحن</b-link>
          <b-link>الشروط والأحكام</b-link>
          <b-link>سياسة الخصوصية</b-link>
        </b-col>
        <b-col>
          <h3>روابط</h3>
          <b-link :to="{ name: 'history' }">بلاغاتي</b-link>
          <b-link :to="{name: 'login'}" v-if="!$store.state.userData || !$store.state.userData.national_id">تسجيل دخول</b-link>
        </b-col>
        <b-col md="3">
          <div>
            <div class="d-flex align-items-center mt-0 mb-50">
              <b-img width="200" :src="require('@/assets/images/logo/logo-white.png')" alt="مساعد نجم" />
            </div>

            <span class="text-white">
              تم تطوير خدمة "مساعد نجم" بهدف تسهيل إجراءات الحوادث المرورية وتجنب تعطيل حركة السير. توفر الخدمة بلاغات
              سريعة وسهلة عبر الموقع الإلكتروني، يتم استلام البلاغات فوراً من قبل فريق "مساعد نجم" لمعالجة الحوادث. يمكن
              لأطراف الحادث المغادرة بعد تقديم البلاغ، مما يقلل من وقت التعامل مع الحوادث ويحسن الأمان والسلامة على الطرق.

            </span>
            <div class="d-flex align-items-center">
              <b-link href="https://twitter.com/najm_ksa" target="_blank" class="mr-1"><font-awesome-icon size="xl"
                  :icon="['fab', 'twitter']" /></b-link>
              <b-link href="https://sa.linkedin.com/company/najm-company-for-insurance-services" target="_blank"
                class="mr-1"><font-awesome-icon :icon="['fab', 'linkedin-in']" /></b-link>
            </div>

            <p class="text-white mb-0">عمل الطالب: طلال اليحيى</p>
          </div>
        </b-col>
      </b-row>
    </div>
    <p class="text-center">
      <span class="mt-25 text-white">
        جميع الحقوق محفوظة © {{ new Date().getFullYear() }} لدى
        <b-link class="ml-25 njm-name" :to="{ name: 'about' }">مساعد نجم</b-link>
      </span>
    </p>

  </footer>
</template>

<script>
import { BLink, BRow, BCol, BImg, BButton } from 'bootstrap-vue'

import { library } from "@fortawesome/fontawesome-svg-core";
import { faMobileScreenButton } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

library.add(faTwitter, faLinkedinIn, faMobileScreenButton);

export default {
  components: {
    BImg,
    BLink,
    BButton,
    BRow,
    BCol,
  },
  methods: {
    appButtonClicked() {
      alert('قريبًا ..')
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

footer {
  &.footer {
  h3 {
    text-wrap: nowrap !important;
  }
    margin-top: 3rem !important;
    background-color: #55565A;
    padding: 0.7rem $content-padding;
    a:not(.call) {
    color: $white;
    opacity: 0.9;
    margin: 15px 0;
    transition: opacity 0.15s;
    &:not(.njm-name) {
      display: table;
    }
    &:hover {
      opacity: 0.6;
    }
  }
  }
}

.f-long {
  margin-top: 20px !important;
  max-width: 1500px;
  margin: auto;

  h1,
  h2,
  h3,
  h4 {
    color: $white;
    margin-bottom: 1.2rem;
  }
}

</style>
