<template>
  <b-navbar style="background-color: var(--gray)">
    <b-row style="max-width: 1300px; margin:auto;" class="w-100 align-items-center">
      <b-col class="d-flex justify-content-center align-items-center">
        <b-link href="https://twitter.com/najm_ksa" target="_blank" class="mr-1 border-right pr-1"><font-awesome-icon
            size="xl" :icon="['fab', 'twitter']" /></b-link>
        <b-link href="https://sa.linkedin.com/company/najm-company-for-insurance-services" target="_blank"
          class="mr-1"><font-awesome-icon :icon="['fab', 'linkedin-in']" /></b-link>
      </b-col>
      <b-col class="text-center">
        اتصل بنا: <b-link href="tel:199033">199033

        </b-link>
      </b-col>
      <b-col class="text-right">
        <b-link class="text-primary font-weight-bold">English <small style="font-size:7px;">Soon</small></b-link>
      </b-col>
    </b-row>
  </b-navbar>
</template>
<script>
import { BNavbar, BRow, BCol, BLink } from 'bootstrap-vue'

export default {
  components: {
    BNavbar,
    BRow,
    BCol,
    BLink
  }
}
</script>

</script>