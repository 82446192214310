<template>
  <ul>
    <li
  v-for="item in items"
        :key="item.title"
        :item="item"
      class="nav-item"
      :class="{
        'active': ActiveRoute == item.route,
        'disabled': item.disabled
      }"
    >
      <b-link
        class="d-flex align-items-center"
        @click="goTo(item.route)"
      >
        <feather-icon :icon="item.icon || 'MinusIcon'" />
        <span class="menu-title text-truncate">{{ t(item.route) }}</span>
      </b-link>
    </li>

  </ul>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  components: {
    BLink
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ActiveRoute() {
      return this.$route.name;
    }
  },
  methods: {
    goTo(route) {
      this.$router.push({name: route});
      this.$emit('close')
    }
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      // i18n
      t,
    }
  },
}
</script>
