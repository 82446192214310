import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // {
    //   path: '/',
    //   name: 'home',
    //   component: () => import('@/views/home'),
    //   meta: {
    //     title: 'الرئيسية'
    //   },
    // },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/views/about'),
      meta: {
        title: 'من نحن'
      }
    },
    {
      path: '/history',
      name: 'history',
      component: () => import('@/views/history'),
      meta: {
        title: 'بلاغاتي',
        requiredLoggedIn: true
      }
    },
    {
      path: '/report/:id',
      name: 'report',
      component: () => import('@/views/report'),
      meta: {
        requiredLoggedIn: true
      }
    },
    {
      path: '/new-report',
      name: 'new-report',
      component: () => import('@/views/new-report'),
      meta: {
        title: 'بلاغ جديد',
        requiredLoggedIn: true,

      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/authentication/Login.vue'),
      meta: {
        title: "تسجيل الدخول",
        redirectLoggedIn: true
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
    },
    {
      path:'/',
      name: 'closed',
      component: () => import('@/views/closed.vue'),
      meta: {
        type: 'closed'
      }
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})


const DEFAULT_TITLE = 'مساعد نجم';
router.beforeEach(async (to, _, next) => {
  if(to.meta.type=='closed') return next();
  return next({name: 'closed'});
  Vue.nextTick(() => {
    document.title = to.meta.title ? ( DEFAULT_TITLE + " | " + to.meta.title ) : DEFAULT_TITLE;
});
  function clearToken(message) {
    Vue.$cookies.remove('t');
    if (message) alert(message);
  }
  const isThereToken = Vue.$cookies.isKey('v');
  const isLoggedIn = store.state.userData && store.state.userData.national_id !==null;
  if (!isThereToken && isLoggedIn) {
    clearToken()
    next()
  }

    if (!isThereToken) {
      if (to.matched.some(record => record.meta.requiredLoggedIn)) {
        next({ name: 'login' })
        return;
      }
      return next()
    }


    if (isThereToken && isLoggedIn) {
      if (to.matched.some(record => record.meta.redirectLoggedIn)) {
        router.back();
        return;
      }
      return next()
    }

    if (isThereToken && !isLoggedIn) {
      fetch('https://api.njm.solutions/user/info', {
        credentials: 'include',
        method: 'GET',
      }).then(res => res.json()).then(response => {
        if (response.status === 200) {
          store.state.userData = response.user;
          if (to.matched.some(record => record.meta.redirectLoggedIn)) {
            next({ name: 'history' })
            return;
          }
          return next()
        } else {
          clearToken(response.message);
          next({ name: 'login' });
        }
      }).catch(e => {
        clearToken('حدث خطأ');
        next({ name: 'login' });
      })

    }

})

router.afterEach((to) => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
